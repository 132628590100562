import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { cartActions } from "../../store/cart-slice";
import { useDispatch, useSelector } from "react-redux";

const InitialDialog = (props) => {

    const [open, setOpen] = useState(false)
    const dispatch = useDispatch();
    const showFirstMessage = useSelector((state) => state?.cart?.showFirstMessage);

    const handleClose = () => {
        setOpen(false)
    }

    const handleDisagree = () => {
        console.log("I do not agree.")
        handleClose()
    }


    useEffect(() => {
        if (!showFirstMessage) {
            setOpen(true)
        } else {
            setOpen(props.open)
        }
        dispatch(
            cartActions.setShowFirstMessage()
        )
    }, [])


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.message}</DialogTitle>
                <DialogActions>

                    <Button onClick={handleDisagree} color="primary"  >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default InitialDialog;
