import NextButtonDetail from "../UI/NextButton";
import Menu from "./Menu";
import Side from "./Side";
import ReactDOM from "react-dom";
import PopUp from "./PopUp";
import Spinner from "../UI/Spinner";
import WarningSpinner from "../UI/WarningSpinner";
import { useSelector } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
const Layout = (props) => {
  const activeStepCart = useSelector((state) => state.cart.activeStep);
  const [activeStep, setActiveStep] = useState();

  useEffect(() => {
    setActiveStep(activeStepCart);
  }, [activeStepCart]);

  return (
    <Fragment>
      <Menu />
      {(activeStep !== "FIVE" && activeStep !== "SIX" && activeStep !== "VIEWATTACHMENT") && (
        <Fragment>
          <div id="main">
            {props.children}
            <div className="align-right" id="next-button-2">
              <NextButtonDetail />
            </div>
          </div>
          <hr />
          <aside id="aside">
            <Side />
          </aside>
          {ReactDOM.createPortal(<NextButtonDetail props={props} />, document.getElementById("socialIcons"))}
        </Fragment>
      )}
      {activeStep === "FIVE" && <Fragment>{props.children}</Fragment>}
      {activeStep === "SIX" && <Fragment>{props.children}</Fragment>}
      {activeStep === "VIEWATTACHMENT" && <Fragment>{props.children}</Fragment>}
      <Spinner />
      <WarningSpinner />
      <PopUp />
    </Fragment>
  );
};

export default Layout;
