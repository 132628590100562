import { API_BASE, JSON_HEADER } from '../constants';

async function sendOrder(data, prices) {
    const retData = await fetch(`${API_BASE}/orders/receive`, {
        // Enter your IP address here

        method: "POST",
        mode: "cors",
        headers: JSON_HEADER,
        body: JSON.stringify({ data: data, prices: prices }),
    });

    const resData = await retData.json()
    return resData

}
export default {
    sendOrder
}