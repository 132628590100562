import { API_BASE, JSON_HEADER } from '../constants';

async function getAllHorniNapis() {

    const url = `${API_BASE}/horni-potisk`
    const data = fetch(url, {
        headers: JSON_HEADER
    }).then(function (response) {
        return response.json();
    }).then(function (responeJson) {
        return responeJson.data;
    });

    return data
}

async function getAllBarvaPotisku() {

    const url = `${API_BASE}/barva-potisk`
    const data = fetch(url, {
        headers: JSON_HEADER
    }).then(function (response) {
        return response.json();
    }).then(function (responeJson) {
        return responeJson.data;
    });

    return data
}

async function getAllObrazkyPotisku() {

    const url = `${API_BASE}/obrazek-potisk`
    const data = fetch(url, {
        headers: JSON_HEADER
    }).then(function (response) {
        return response.json();
    }).then(function (responeJson) {
        return responeJson.data;
    });

    return data
}


export default {
    getAllHorniNapis,
    getAllBarvaPotisku,
    getAllObrazkyPotisku
}