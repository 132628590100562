import { API_BASE, JSON_HEADER } from '../constants';

async function getAllProducts() {
    const url = `${API_BASE}/textil`
    const data = fetch(url, {
        headers: JSON_HEADER,
    })
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            return myJson.data;
        });
    return data;
}

async function getAllExcludedTextil() {
    const url = `${API_BASE}/excluded-textil`
    const data = fetch(url, {
        headers: JSON_HEADER,
    })
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            return myJson;
        });
    return data;
}

async function getProductsFromCategory(categoryId) {
    const url = `${API_BASE}/textil/${categoryId}`
    const data = fetch(url, {
        headers: JSON_HEADER,
    })
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            return myJson.data;
        });
    return data;
}

export default {
    getAllProducts,
    getAllExcludedTextil,
    getProductsFromCategory
};
