import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from '../../store/cart-slice';
import { API_BASE } from '../../constants';

const ShirtThumb = (props) => {

    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const pictureURL = (cart.activeStep !== "THREE" ? "getFrontPicture" : "getBackPicture")
    const textilId = props.oneTextil.textilId;
    const barvaId = props.oneTextil.barvaId;
    const baseUrl = `${API_BASE}/textil/`

    const onShirtThumbClickHandler = (event) => {
        const id = event.target.id;
        dispatch(
            cartActions.setActiveThumb({
                id
            })
        );
        event.stopPropagation();
    }

    const onDeleteShirtHandler = (event) => {
        const id = event.target.id;
        const temp = id.split("#")
        const textilId = temp[1]
        const colorId = temp[2]
        dispatch(
            cartActions.removeTextilFromCart({
                textilId,
                colorId
            })
        );
        event.stopPropagation();
    }

    return (
        <React.Fragment>
            <div className="thumb" id={`gallery_item_${textilId}_${barvaId}`}>
                <a href="##" onClick={onDeleteShirtHandler} id={`removeTextil#${textilId}#${barvaId}`} className="remove">Smazat</a>
                <a href="##">
                    <img src={`${baseUrl}${pictureURL}/${textilId}/${barvaId}/91`} id={`shirtBasketThumb#${textilId}#${barvaId}`} alt="" onClick={onShirtThumbClickHandler} /></a>
            </div>
        </React.Fragment>
    )
}

export default ShirtThumb;