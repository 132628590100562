import React from "react";

const ListsThumbDetail = (props) => {
  const basketPrice = props.basketPrice;

  return (
    <React.Fragment>

      <h3>
        <b>Pamětní listina</b>
      </h3>

      <p>formát A3, papír 180g, struktura pergamenu natištěna z obou stran, z jedné strany listina pergamenu</p>
      <p>
        Cena za ks: {" "}
        {new Intl.NumberFormat("cs-CS", {
          style: "currency",
          currency: "CZK",
        }).format(basketPrice?.lists?.listsItemPrice)}
        <br />
        Celkem ks: {basketPrice.lists.listsQuantity}
      </p>

      <p>
        <strong id="cenaCelkem">Cena celkem: {" "}
          {new Intl.NumberFormat("cs-CS", {
            style: "currency",
            currency: "CZK",
          }).format(basketPrice?.lists?.listsItemPrice * basketPrice.lists.listsQuantity)}</strong>
      </p>

    </React.Fragment>
  );
};

export default ListsThumbDetail;
