import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
// import { thunk } from 'redux-thunk';
import cartSlice from './cart-slice';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";


const reducers = combineReducers({
  cart: cartSlice.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const preloadedState = {
  basket: {
    items: [],
    predniPotisk: {
      horniNapis: undefined,
      potisk: undefined,
      dolniPredniNapisText: undefined,
      barvaPotisku: undefined,
      specialniUmisteni: false,
      predniPotiskVelikostA2: false,
      vlastniObrazek: undefined,
      vlastniObrazekUrl: undefined,
      vlastniObrazekSet: false,
      obrazekPotisku: undefined,
      seznamJmen: undefined,
      ownTextToggle: false,
    },
    zadniPotisk: {
      specialniUmisteni: false,
      zadniPotiskVelikostA2: false,
      vlastniObrazek: undefined,
      vlastniObrazekUrl: undefined,
      vlastniObrazekSet: false,
      ownTextToggle: false,
    },
    sleeves: {
      leftSleeveText: "",
      rightSleeveText: "",
      leftSleeveNickNames: false,
      rightSleeveNickNames: false,
    },
    ribbons: {},
    lists: { chosen: false },
    school: {},
    students: {
      names: [],
      nicks: [],
      mappedNames: [],
    },
    priplatky: [],
    priplatkyCelkem: 0,
    contact: {
      firstname: undefined,
      lastname: undefined,
      phone: undefined,
      email: undefined,
    },
    address: {
      street: undefined,
      city: undefined,
      zip: undefined,
    },
    note: {
      note: undefined,
    },
  },
  data: {
    textilData: [],
    ribbonsData: [],
    tvarSeznamuJmenData: [],
    horniNapisData: [],
    barvaPotiskuData: [],
    obrazkyPotiskuData: [],
    dolniPismoData: [],
  },
  totalQuantity: 0,
  totalPrice: 0,
  changed: false,
  allProducts: [],
  horniNapisActivePage: 0,
  horniNapisChosenPage: 0,
  horniNapisChosenPageShift: 0,
  obrazekPotiskuActivePage: 0,
  obrazekPotiskuChosenPage: 0,
  obrazekPotiskuChosenPageShift: 0,
  displayPopupObrazekPotisku: false,
  displayPopupHorniNapis: false,
  displayPopupTvarSeznamuJmen: false,
  popup: {},
  horniNapisContentStyle: {},
  horniNapisShift: 0,
  horniNapisWidth: 100000,
  obrazekPotiskuShift: 0,
  zadniPotiskOwnTextToggle: false,
  activeThumb: undefined,
  activeStep: "StepOne",
  activeStepNumber: 1,
  showWarning: true,
  lastValidStep: 1,
};


const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  // middleware: [thunk],
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    // serializableCheck: {
    //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    // },
  }),
  // preloadedState
});


export default store;
