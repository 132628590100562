import React, { } from "react";
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { API_BASE } from "../../constants";

const ListsThumb = (props) => {
  const dispatch = useDispatch();

  const onShirtThumbClickHandler = (event) => {
    const id = event.target.id;
    dispatch(cartActions.setListsThumbChosen());
    event.stopPropagation();
  };

  const onDeleteListHandler = (event) => {
    dispatch(cartActions.removeListFromCart());
    event.stopPropagation();
  };

  return (
    <React.Fragment>
      <div className="thumb" id="gallery_item_lists_4">
        <a href="##" onClick={onDeleteListHandler} id={`removeList`} className="remove">
          Smazat
        </a>
        <a href="##">
          <img src={`${API_BASE}/listina/getPicture/4/100`} alt="" onClick={onShirtThumbClickHandler} />
        </a>
      </div>
    </React.Fragment>
  );
};

export default ListsThumb;
