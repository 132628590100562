import { API_BASE, JSON_HEADER } from "../constants";

async function getAllItems() {
  const url = `${API_BASE}/seznam-jmen`;
  const data = fetch(url, {
    headers: JSON_HEADER,
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (responeJson) {
      return responeJson.data;
    });

  return data;
}

export default {
  getAllItems,
};
