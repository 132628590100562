import React from "react";
import { API_BASE } from "../../constants";

const StepFiveRibbonsSummary = (props) => {
  const basketPrice = props.basketPrice;
  const basket = props.basket
  console.log("🚀 ~ StepFiveRibbonsSummary ~ basket:", basket)

  const getRibbonColor = () => {
    if (basket?.ribbons?.colorData?.id !== undefined) {
      return basket?.ribbons?.colorData?.id
    } else {
      return basket?.ribbons?.id.split("|")[2]
    }
  }

  return (
    <React.Fragment>
      <article className="sep active">
        <div className="shirt clearfix">
          <div className="thumb">
            <img src={`${API_BASE}/ribbons/getPicture/${getRibbonColor()}`} alt="" />{" "}
          </div>
          <div className="content">
            <h2>Pamětní šerpa </h2>
            <p>
              Cena za ks:{" "}
              {new Intl.NumberFormat("cs-CS", {
                style: "currency",
                currency: "CZK",
              }).format(basketPrice?.ribbons?.ribbonItemPrice)}
              <br />
              {(basketPrice?.ribbons?.ribbonPrintColorPrice > 0) &&
                <>
                  Cena příplatkové barvy: {new Intl.NumberFormat("cs-CS", {
                    style: "currency",
                    currency: "CZK",
                  }).format(basketPrice?.ribbons?.ribbonPrintColorPrice)}
                  <br />
                </>
              }
              {(basketPrice?.ribbons?.ribbonsNicks == true) &&
                <>
                  Cena příplatku za přezdívky: {new Intl.NumberFormat("cs-CS", {
                    style: "currency",
                    currency: "CZK",
                  }).format(50)}
                  <br />
                  Přezdívky ks: {basketPrice?.ribbons?.ribbonsNicksSize}
                  <br />
                </>
              }
              Celkem ks: {basketPrice?.ribbons?.ribbonsQuantity}
              <br />
              Cena celkem:{" "}
              {new Intl.NumberFormat("cs-CS", {
                style: "currency",
                currency: "CZK",
              }).format((parseInt(basketPrice?.ribbons?.ribbonsPrice)))}
            </p>
          </div>
        </div>
      </article>
    </React.Fragment>
  );
};

export default StepFiveRibbonsSummary;
