import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart-slice";

const SchoolInfoName = (data) => {
  const width95 = { width: "95%" };

  const itemValue = useSelector((state) => state.cart.basket.students.names[data.id - 1]?.name);
  const dispatch = useDispatch();

  const onSchoolItemChangeHandler = (event) => {
    dispatch(
      cartActions.setSchoolNamesData({
        id: event.target.id,
        value: event.target.value,
      })
    );
  };

  return (
    <React.Fragment>
      <div className="row clearfix">
        <div className="num">{data.id}</div>
        <div className="nameInput">
          <input type="text" style={width95} id={`studentName#${data.id}`} value={itemValue} onChange={onSchoolItemChangeHandler} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SchoolInfoName;
