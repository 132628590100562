import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import NickListName from './NickListName';

const NickList = (data) => {

  const dispatch = useDispatch();

  const ribbonsData = useSelector((state) => state.cart.basket.ribbons);

  const [ribbonsNicksCheckboxStatus, setRibbonsNicksCheckboxStatus] = useState(false);
  const [showRibbonsNicks, setShowRibbonsNicks] = useState(false);

  const defaultNameLinesCount = 30;
  const [nameLinesCount, setNameLinesCount] = useState([]);

  const pageInit = useCallback(async () => {
    setNameLinesCount(30)
    setRibbonsNicksCheckboxStatus(ribbonsData.addNicks)
    setShowRibbonsNicks(ribbonsData.addNicks)

  }, [])

  const onAddNamesRowClickHandler = (event) => {
    setNameLinesCount(nameLinesCount + 1)
  }


  const toggleNicksHandler = (event) => {
    dispatch(
      cartActions.toggleRibbonsNicks({
        checked: event.target.checked
      })
    );
    setRibbonsNicksCheckboxStatus(event.target.checked)
    setShowRibbonsNicks(event.target.checked)
  }

  useEffect(() => {
    pageInit();
  }, [pageInit]);


  return (
    <React.Fragment>
      <div id="own_nicks">
        <input type="checkbox" onChange={toggleNicksHandler} value="serpyRibbonNickList" checked={ribbonsNicksCheckboxStatus} id="serpyRibbonNickList" />
        <label htmlFor="serpyRibbonNickList">Přidat přezdívky (+70Kč)</label>
        {showRibbonsNicks &&
          <><div id="nickList">
            <a name="seznam"></a>
            <h2 className="strip n1">Seznam přezdívek</h2>
            <fieldset className="names">
              <div className="rows">
                {[...Array(Math.max(nameLinesCount, 30))].map((elementInArray, i) => (
                  <NickListName id={i + 1} />
                ))}
              </div>
              <div className="row clearfix" onClick={onAddNamesRowClickHandler}>
                <div className="num bold">+</div>
                <div className="nameInput">
                  Přidat další pole
                </div>
                <div className="nickInput">
                  &nbsp;
                </div>
                <div className="remove">&nbsp;</div>
              </div>

            </fieldset>
          </div></>
        }
      </div>
    </React.Fragment >
  );
};

export default NickList;
