import React, { useEffect } from 'react';
import BarvaPotiskuItem from './BarvaPotiskuItem';



const BarvaPotisku = (data) => {
    function groupByKey(array, key) {
        return array
            .reduce((hash, obj) => {
                if (obj[key] === undefined) return hash;
                return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
            }, {})
    }

    function getTitle(price, pricea2, ribbons) {
        let a2Text = ``
        if (!ribbons)
            a2Text = ` jedna strana potisku jednoho kusu textilu`
        const title = (price > 0 ? `Příplatkové barvy (${price},00 Kč${a2Text})` : "Základní barvy potisku (bez příplatku)")
        return title
    }
    useEffect(() => {
    }, []);

    const groupedData = groupByKey(data.barvaPotisku, "cena");

    return (
        <React.Fragment>
            <div className="relative">
                <h2 className="strip n2">Barva potisku</h2>
            </div>
            <br />
            <div className="sliderInfo">
                <table className="colors">
                    <tbody>
                        {Object.entries(groupedData).map(([key, value]) => (
                            <React.Fragment key={key}>

                                <tr className="color_group">
                                    <td><strong>
                                        {
                                            getTitle(key, value[0]["cena_trickoa2"], data.ribbons)
                                        }
                                    </strong></td>
                                </tr>
                                {value.map((oneColor) => (
                                    <BarvaPotiskuItem kod={oneColor.kod} id={oneColor.id} key={'barvaPotisku|' + oneColor.kod + '|' + oneColor.id} rgb={oneColor.rgb} popis={oneColor.popis} ribbons={data.ribbons} price={key} />
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </React.Fragment>

    )
}

export default BarvaPotisku
