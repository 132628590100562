import {createPortal} from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import FadeInOut from "./FadeInOut";
import { cartActions } from "../../store/cart-slice";

const WarningSpinnerDetail = () => {
    const cart = useSelector((state) => state.cart);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
 
        setShow(cart?.showWarning)
        setTimeout(() => {
            dispatch(
                cartActions.hideWarning()
            );
        }, 2000);


       
    }, [cart?.showWarning, cart.warningMessage, dispatch]);


    return ("",
        <React.Fragment >
            <FadeInOut show={show} duration={2000} ><div id="warning">{cart?.warningMessage}</div></FadeInOut>
        </React.Fragment >
    );
}

const WarningSpinner = (props) => {
    return (
        <React.Fragment >
            {createPortal(< WarningSpinnerDetail props={props} />, document.getElementById('warningSpinner'))}
        </React.Fragment>
    );
}

export default WarningSpinner;

