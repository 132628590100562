import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from '../../store/cart-slice';

const SchoolInfoItem = (data) => {

    const itemValue = useSelector((state) => state.cart.basket.school[data.id]);
    const dispatch = useDispatch();

    const className = data.isMiddle ? "col middle" : "col"

    const onSchoolItemChangeHandler = (event) => {
        dispatch(
            cartActions.setSchoolInfoData({
                id: data.id,
                value: event.target.value
            })
        );
    }


    return (
        <React.Fragment>
            <div className={className}>
                <label>{data.label}</label>
                <input type="text" className="text" id={data.id} value={itemValue} onChange={onSchoolItemChangeHandler} />
            </div>
        </React.Fragment>
    )

}

export default SchoolInfoItem