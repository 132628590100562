import { API_BASE, JSON_HEADER, HTML_HEADER } from "../constants";

async function getAllItems() {
  const url = `${API_BASE}/dolni-pismo`;
  const data = fetch(url, {
    headers: JSON_HEADER,
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (responeJson) {
      return responeJson.data;
    });

  return data;
}

async function getPismoPreviewData(pismoId) {
  try {
    const url = `${API_BASE}/dolni-pismo/${pismoId}`;
    const data = await fetch(url, {
      headers: HTML_HEADER,
    }).then(function (response) {
      return response.text();
    });
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function getPismoPreview(pismoId) {
  const data = await getPismoPreviewData(pismoId);
  return data;
}

export default {
  getAllItems,
  getPismoPreview,
  getPismoPreviewData,
};
