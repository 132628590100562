import React, { useEffect, useState, useCallback } from 'react';
import ReactDOM from "react-dom";
import { cartActions } from '../../store/cart-slice';
import { useDispatch, useSelector } from 'react-redux';
import genericService from "../../services/generic.service";

const NextButtonDetail = (props) => {
    const dispatch = useDispatch();
    const activeStep = useSelector((state) => state.cart.activeStep);
    const [nextStepUrl, setNextStepUrl] = useState([]);
    const data = useSelector((state) => state.cart.data);

    const resetStore = (event) => {
        dispatch(cartActions.resetStore({}));
    }

    const onNextStepClick = (event) => {
        event.preventDefault();
        window.location.href = nextStepUrl;
    }

    useEffect(() => {
        switch (activeStep) {
            case "TWO":
                setNextStepUrl('/stepThree')
                break;
            case "THREE":
                setNextStepUrl('/stepFour')
                break;
            case "FOUR":
                setNextStepUrl('/stepFive')
                break;
            default:
                setNextStepUrl('/stepTwo')
        }
    }, [activeStep]);

    return (
        <React.Fragment>
            {genericService.getParamNValue(data, 'DEBUG') !== 1 && <button type="button" className="button green" onClick={resetStore}>ResetStore</button>}
            <button type="button" className="button green" onClick={onNextStepClick}>Pokračovat</button>
        </React.Fragment>
    );
}

const NextButton = (props) => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(<NextButtonDetail props={props} />, document.getElementById('socialIcons'))}
        </React.Fragment>
    );
}

export default NextButtonDetail;
