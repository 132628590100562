import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
// import Select from "react-dropdown-select";
import Select from "react-select";
import TextilDetail from "../UI/TextilDetail";
import { API_BASE } from "../../constants";

const StepFiveShirtSummary = (props) => {
    const dispatch = useDispatch();
    const headerSize = "2";
    const basket = props.basket;
    const oneItem = props.textil;
    const baseUrl = `${API_BASE}/textil/`;
    const pictureURL = "getFrontPicture";
    const activeStep = props.activeStep;
    const data = props.data;

    const [mappedNames, setMappedNames] = useState();

    const nvl = (value) => {
        if (value === undefined) return "";
        else return value;
    };

    const isOptionDisabled = (oneId) => {
        const idSplit = oneId.split("|");
        const code = idSplit[0];
        const id = idSplit[1];
        const isAssigned = typeof basket.students.names[id - 1]?.textil !== "undefined";
        const value = basket.students.names.find((element) => {
            return element.textil === code;
        });
        if (typeof value !== "undefined" && isAssigned) return false;
        return isAssigned;
    };

    const onDisableSelectedName = (event) => {
        const value = event.target.value;
        dispatch(
            cartActions.addNameToTextil({
                value,
            })
        );
    };

    const clearValues = (event) => { };

    const getOptions = (id) => {
        let selectedOptions = [];
        let options = [];
        Object.keys(basket.students.mappedNames).forEach(function (key) {
            if (key !== id && key !== undefined) {
                const vals = basket.students.mappedNames[key];
                if (Array.isArray(vals))
                    vals?.map((mappedName) => {
                        selectedOptions.push(mappedName.value);
                    });
            }
        });

        const names = basket?.students?.names;
        const namesLength = names?.filter(function (el) {
            return el?.name?.trim().length > 0;
        });

        const nicks = basket?.students?.nicks;
        const nicksLength = nicks?.filter(function (el) {
            return el?.name?.trim().length > 0;
        });

        let firstList = [];
        let secondList = []

        if (nicksLength?.length > 0) {
            firstList = nicks
            secondList = names
        } else {
            firstList = names
            secondList = nicks
        }

        let myOptions = []
        for (let index = 0; index < firstList.length; index++) {
            myOptions.push({
                // label: (nvl(firstList[index]?.name) + " " +  nvl(secondList[index]?.name)).trim(),
                label: (nvl(firstList[index]?.name)).trim(),
                textilId: id,
                value: index,
                rating: selectedOptions.includes(index) ? "selected" : "safe",

            })

        }

        myOptions = myOptions?.filter(function (el) {
            return (nvl(el?.label)).trim().length > 0;
        });
        return myOptions;
    };

    const onSelect = (event, aType) => {
        let textilId = undefined;

        if (aType.action === "clear" || (aType.action === "remove-value" && event.length === 0)) {
            if (event.length === 0 && aType.action !== "clear") {
                textilId = aType.removedValue.textilId;
            } else {
                textilId = aType.removedValues[0].textilId;
            }
            dispatch(
                cartActions.removeNameFromTextil({
                    textilId: textilId,
                })
            );
        } else {
            if (event.length > 0) textilId = event[0].textilId;
            dispatch(
                cartActions.addNameToTextil({
                    textilId: textilId,
                    event: event,
                })
            );
        }
    };

    const getSelectedOptions = (code) => {
        return basket.students.mappedNames[code];
    };

    const CustomTag = `h${props.level}`;

    return (
        <React.Fragment>
            <article className="sep active" id={`shirtBasketSummary#${oneItem.id}`} key={`shirtBasketSummary#${oneItem.id}`}>
                <div className="shirt clearfix">
                    <div className="thumb">
                        <img src={`${baseUrl}${pictureURL}/${oneItem.textil}/${oneItem.colorId}/91`} id={`shirtBasketSummaryThumb#${oneItem.textil}#${oneItem.colorId}`} alt="" />
                    </div>
                    <div className="content">
                        <h2>
                            {oneItem.title} - {oneItem.colorName} - Velikost {oneItem.size}
                        </h2>
                        <p>
                            <TextilDetail item={oneItem} basket={basket} totalCount={oneItem.quantity} activeStep={activeStep} data={data} />
                        </p>
                        {(basket.sleeves?.leftSleeveNickNames || basket.sleeves?.rightSleeveNickNames) && (
                            <fieldset style={{ width: "250px" }}>
                                <Select
                                    name={`basketNameOption#${oneItem.id}`}
                                    isMulti
                                    options={getOptions(oneItem.id)}
                                    onChange={onSelect}
                                    isOptionDisabled={(option) => option?.rating !== "safe"}
                                    className="conclusion_names_select"
                                    defaultValue={getSelectedOptions(oneItem.id)}
                                    noOptionsMessage={() => "Wählen Sie"}
                                    clearValue={clearValues}
                                />
                            </fieldset>
                        )}
                    </div>
                </div>
            </article>
        </React.Fragment>
    );
};

export default StepFiveShirtSummary;
