import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import TextilDetail from "../UI/TextilDetail";

const ShirtThumbDetail = (props) => {
  const basket = useSelector((state) => state.cart.basket);
  const data = useSelector((state) => state.cart.data);
  const chosenThumb = useSelector((state) => state.cart.activeThumb);
  const items = basket !== undefined ? basket.items : [];
  const changed = useSelector((state) => state.cart.changed);
  const [foundItem, setFoundItem] = useState();
  const [itemFound, setItemFound] = useState(false);
  const [totalCount, setTotalCount] = useState(false);

  const getCountPerTextil = (data) => {
    let totalCount = 0;
    data.forEach(function (element) {
      totalCount += parseInt(element.quantity);
    });
    setTotalCount(totalCount);
  };
  const getDetails = useCallback(async () => {
    if (chosenThumb !== undefined) {
      let itemPrice = 0;

      setItemFound(false);
      const tmp = chosenThumb.split("#");
      const textilId = tmp[1];
      const colorId = tmp[2];

      if (items !== undefined && items.length > 0) {
        const foundItem = items.filter((e) => e.textil === textilId && e.quantity > 0 && parseInt(e.colorId) === parseInt(colorId));

        if (foundItem.length > 0) {
          setItemFound(true);
          setFoundItem(foundItem);
          getCountPerTextil(foundItem);
        } else if (items.length > 0) {
          setItemFound(true);
          setFoundItem([items[0]]);
          getCountPerTextil([items[0]]);
        }
        itemPrice = foundItem[0]?.price;
      } else {
        setItemFound(false);
      }
    } else {
      setItemFound(false);
    }
  }, [chosenThumb, items, changed, basket]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  return (
    <React.Fragment>
      {itemFound && (
        <div>
          <h3>
            <b>
              {foundItem[0].title} - {foundItem[0].colorName}
            </b>
          </h3>

          <p>
            {itemFound &&
              foundItem.map((foundItem) => (
                <span key={foundItem.id}>
                  Velikost: {foundItem.size}, Počet kusů: {foundItem.quantity}
                  <br />
                </span>
              ))}
            <TextilDetail item={foundItem[0]} basket={basket} totalCount={totalCount} data={data} />
          </p>

          <p>
            <strong>Náhled potisku může být chybně posunutý nebo malý. Umístění potisku je jen orientační.Natiskneme jej standartně uprostřed.</strong>
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

export default ShirtThumbDetail;
