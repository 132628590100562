import React, { useState } from "react";
import { cartActions } from "../../store/cart-slice";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE } from "../../constants";

const PrintItemDetail = (data) => {
  const [svgData, setSvgData] = useState([]);
  const [imgLoaded, setImgLoaded] = useState(false);

  const predniPotisk = useSelector((state) => state.cart.basket.predniPotisk);
  const activeStep = useSelector((state) => state.cart.activeStep);
  const kod = data.kod;
  const id = data.id;
  const kodLabel = "Kód " + kod;
  const noKodLabel = "-";
  const itemType = data.itemType;
  const dataType = data.dataType;
  const endPoint = data.endPoint;
  const itemId = itemType + "|" + kod + "|" + id;
  const classLabel = id !== "0" ? "item" : "item noprint";
  const dispatch = useDispatch();
  const itemsPerPage = data.itemsPerPage;
  const oneImageWidth = data.oneImageWidth;
  const itemDetailUrl = `${API_BASE}/${endPoint}/${id}`;

  const isItemActive = (kod) => {
    let status = "";
    const chosenItem = predniPotisk[itemType];
    if (itemId === chosenItem) {
      status = "active";
    }
    return status;
  };

  const onTvarSeznamuJmenItemClickHandler = (event) => {
    const id = event.target.id;
    dispatch(
      cartActions.addPrintItemToTheCart({
        id: id,
        itemType: itemType,
        dataType: dataType,
        imagesPerPage: itemsPerPage,
        oneImageWidth: oneImageWidth,
        colorData: data.colorData
      })
    );
  };


  const noPrint = "Žádný potisk";
  const imgId = "img|" + itemId;

  return (
    <React.Fragment>
      <div className={classLabel} onClick={onTvarSeznamuJmenItemClickHandler} id={"div|" + itemId}>
        <small>{id !== "0" ? kodLabel : noKodLabel}</small>
        <a href="##" className={isItemActive(itemId)} rel={`${id}`} id={"href|" + itemId}>
          {id !== "0" ? (
            <img src={itemDetailUrl} id={imgId} alt={itemId} />
          ) : (
            noPrint
          )}
        </a>
      </div>
    </React.Fragment>
  );
};


export default PrintItemDetail;
