import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { API_BASE } from "../../constants";
import { useSelector } from "react-redux";

const ShirtPreview = (props) => {
  const dispatch = useDispatch();
  const basket = useSelector((state) => state.cart?.basket);
  const [imageUrl, setImageUrl] = useState(false);
  const idSplit = (basket?.ribbons?.id !== undefined?basket?.ribbons?.id.split("|"):undefined);
  const ribbonId = props.ribbonId !== undefined ? idSplit[2] : undefined;

  const onShirtPreviewLoad = (event) => {
    dispatch(cartActions.loadingFinished());
  };

  useEffect(() => {
    if (props.listThumbChosen) {
      setImageUrl(`${API_BASE}/listina/getPicture/4/270`);
    } else if (props.ribbonsThumbChosen) {
      setImageUrl(`${API_BASE}/ribbons/getPicture/${ribbonId}/200`);
    } else {
      if (props.activeThumb === undefined) {
        setImageUrl(`${API_BASE}/shirtPreview/front/s-1/sc-1`);
      } else {
        setImageUrl(props.imageUrl);
      }
    }
  }, [props.imageUrl, props.listThumbChosen, props.activeThumb, props.ribbonsThumbChosen, basket?.ribbons?.id]);

  return (
    <React.Fragment>
      <img id="shirtPreview" src={imageUrl.toString()} alt="" onLoad={onShirtPreviewLoad} />
    </React.Fragment>
  );
};

export default ShirtPreview;
