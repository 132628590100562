import React from "react";

const RibbonsThumbDetail = (props) => {
  const ribbons = props.basketPrice?.ribbons;
  const ribbonPrintColorPrice = parseInt(ribbons?.ribbonPrintColorPrice);
  const ribbonItemPrice = parseInt(ribbons?.ribbonItemPrice);
  const ribbonsQuantity = parseInt(ribbons.ribbonsQuantity);
  const ribbonsTotalPrice = (ribbonItemPrice + ribbonPrintColorPrice) * ribbonsQuantity;

  return (
    <React.Fragment>
      <h3>
        <b>Pamětní šerpa</b>
        <br />
      </h3>
      <p>
        Cena za ks:{" "}
        {new Intl.NumberFormat("cs-CS", {
          style: "currency",
          currency: "CZK",
        }).format(ribbonItemPrice)}
        <br />
        {ribbonPrintColorPrice > 0 && (
          <>
            Cena příplatkové barvy:{" "}
            {new Intl.NumberFormat("cs-CS", {
              style: "currency",
              currency: "CZK",
            }).format(ribbonPrintColorPrice)}
            <br />
          </>
        )}
        Celkem ks: {ribbonsQuantity}
        <br />
      </p>
      <p>
        <strong id="cenaCelkem">
          Cena celkem:{" "}
          {new Intl.NumberFormat("cs-CS", {
            style: "currency",
            currency: "CZK",
          }).format(ribbonsTotalPrice)}
        </strong>
      </p>
    </React.Fragment>
  );
};

export default RibbonsThumbDetail;
