import React, { useEffect } from "react";
import { API_BASE } from "../../constants";

const StepFiveListsSummary = (props) => {
  const basketPrice = props.basketPrice;
  const listsQuantity = basketPrice.lists?.listsQuantity !== undefined ? basketPrice.lists?.listsQuantity : 0

  return (
    <React.Fragment>
      {(listsQuantity > 0) && <article className="sep active">
        <div className="shirt clearfix">
          <div className="thumb">
            <img src={`${API_BASE}/listina/getPicture/4/100`} alt="" />
          </div>
          <div className="content">
            <h2>Pamětní listina</h2>
            <p>
              Cena za ks:{" "}
              {new Intl.NumberFormat("cs-CS", {
                style: "currency",
                currency: "CZK",
              }).format(basketPrice?.lists?.listsItemPrice)}
              <br />
              Celkem ks: {listsQuantity}
              <br />
              Cena celkem:{" "}
              {new Intl.NumberFormat("cs-CS", {
                style: "currency",
                currency: "CZK",
              }).format(basketPrice?.lists?.listsItemPrice * basketPrice.lists?.listsQuantity)}
            </p>
          </div>
        </div>
      </article>}
    </React.Fragment>
  );
};

export default StepFiveListsSummary;
