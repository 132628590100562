import ReactDOM from "react-dom";
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import FadeInOut from "./FadeInOut";

const SpinnerDetail = () => {
    const cart = useSelector((state) => state.cart);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(cart?.showLoading);
    }, [cart?.showLoading]);

    return ("",
        <React.Fragment >
            <FadeInOut show={show} duration={500} ><div id="loading"> Nahrávám obsah, moment prosím...</div ></FadeInOut>
        </React.Fragment >
    );
}

const Spinner = (props) => {
    return (
        <React.Fragment >
            {ReactDOM.createPortal(< SpinnerDetail props={props} />, document.getElementById('spinner'))}
        </React.Fragment>
    );
}

export default Spinner;