import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart-slice";

const NickListName = (data) => {
  const width95 = { width: "95%" };

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const itemValue = useSelector((state) => state.cart.basket.ribbons?.nicks[data.id - 1]);

  const onRibbonNickChangeHandler = (event) => {
    dispatch(
      cartActions.setRibbonsNick({
        id: event.target.id,
        value: event.target.value,
      })
    );

  };

  return (
    <React.Fragment>
      <div className="row clearfix" key={`ribbonNick#${data.id}`}>
        <div className="num">{data.id}</div>
        <div className="nameInput">
          <input type="text" style={width95} id={`ribbonNick#${data.id}`} value={itemValue} onChange={onRibbonNickChangeHandler} />
        </div>

      </div>
    </React.Fragment>
  );
};

export default NickListName;
